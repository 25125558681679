import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { animated, useSpring, config } from 'react-spring'
import Layout from '../components/layout'
import GridItem from '../components/grid-item'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'

type PageProps = {
  data: {
    threeProjects: {
      nodes: {
        title: string
        slug: string
        cover: ChildImageSharp
      }[]
    }
    aboutUs: ChildImageSharp
    menu: ChildImageSharp
  }
}

const Area = styled(animated.div)`
  display: grid;
  padding-top: 30px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 35vw 40vw;
  grid-template-areas:
    'first-project about-us about-us'
    'three-projects three-projects three-projects';

  @media (max-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 35vw 30vw 30vw 25vw;

    grid-template-areas:
      'first-project first-project about-us about-us'
      'three-projects three-projects three-projects three-projects'
      'three-projects three-projects three-projects three-projects';
  }

  @media (max-width: ${(props) => props.theme.breakpoints[2]}) {
    padding-top: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 38vw);
    padding-top: 0px;

    grid-template-areas:
      'first-project about-us'
      'three-projects three-projects';
  }

  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    padding-top: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 50vw);

    grid-template-areas:
      'first-project'
      'about-us'
      'three-projects';
  }
`

const FirstProject = styled(GridItem)`
  grid-area: first-project;
`

const AboutUs = styled(GridItem)`
  grid-area: about-us;
`

const ThreeProjects = styled.div`
  grid-area: three-projects;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (max-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`

const Index: React.FunctionComponent<PageProps> = ({ data: { menu, threeProjects, aboutUs } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#f4f4f4">
      <SEO />
      <Area style={pageAnimation}>
        <FirstProject to="/menu">
          <Img fluid={menu.childImageSharp.fluid} />
          <span>Menü</span>
        </FirstProject>
        <AboutUs to="/hakkimizda" aria-label="Hakkımızda sayfasını ziyaret edin">
          <Img fluid={aboutUs.childImageSharp.fluid} />
          <span>Hakkımızda</span>
        </AboutUs>
        <ThreeProjects>
          {threeProjects.nodes.map((project) => (
            <GridItem to={project.slug} key={project.slug} aria-label={`"${project.title}"`}>
              <Img fluid={project.cover.childImageSharp.fluid} />
              <span>{project.title}</span>
            </GridItem>
          ))}
        </ThreeProjects>
      </Area>
    </Layout>
  )
}

export default Index

export const query = graphql`
  query Index {
    threeProjects: allProjectsYaml(limit: 3) {
      nodes {
        title
        slug
        cover {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    menu: file(sourceInstanceName: { eq: "images" }, name: { eq: "antrikot" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aboutUs: file(sourceInstanceName: { eq: "images" }, name: { eq: "about-us" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
